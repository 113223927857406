<template>
  <div>
    <b-row>
      <b-col>
        <b-select v-model="combatOptionLocal" :options="combatOptionList">-- Please select an option --</b-select>
      </b-col>
      <b-col>
        <b-select v-model="combatOptionMeasureLocal" :options="combatOptionMeasureList"></b-select>
      </b-col>
      <b-col>
        <b-input></b-input>
        <b-select></b-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'CombatOptionsChoose',
  props: {
    combatOption: {
      type: String
    },
    combatOptionMeasure: {
      type: String
    }
  },
  data () {
    return {
      combatOptionLocal: this.combatOption,
      combatOptionMeasureLocal: this.combatOptionMeasure,
      combatOptionList: [
        {
          value: 'task',
          text: 'Задача'
        },
        {
          value: 'lead',
          text: 'Лид'
        },
        {
          value: 'deal',
          text: 'Сделка'
        },
        {
          value: 'invoice',
          text: 'Счет'
        },
        {
          name: 'quote',
          text: 'Предложение'
        }
      ],
      measures: [
        {
          value: 'pcs',
          text: 'шт'
        },
        {
          value: 'rub',
          text: 'руб'
        },
        {
          value: 'hour',
          text: 'час'
        }
      ]
    }
  },
  computed: {
    combatOptionMeasureList () {
      let measures = []

      if (this.combatOptionLocal === 'task') {
        measures = this.measures.filter(el => el.value !== 'rub')
      } else {
        measures = this.measures.filter(el => el.value !== 'hour')
      }

      return measures
    }
  }
}
</script>

<style>

</style>

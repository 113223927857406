<template>
  <div>
      <div>
        <template
          v-if="users.length">
          <div
            v-for="(user, index) in users"
            :key="user.key"
            class="d-flex justify-content-between align-items-center mb-2">
            <div>
              <div
                @click="userToggle(user)">
                <template v-if="checked && index === 0">👑</template>
                <template v-else-if="checked">✔️</template>
                <template v-else>➕</template>
              </div>
            </div>
            <div>
              <img
                width="40"
                :src="user.avatar"
                :alt="user.name">
            </div>
            <div>
                <div>{{ user.name }}</div>
                <div>Пригласить в команду</div>
            </div>
            <div>
                <div>Победы</div>
                <div>{{ user.wins_count }}</div>
            </div>
            <div>
                <div>Очки</div>
                <div>{{ user.points_count }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            Пользователей нет
          </div>
        </template>
      </div>
  </div>
</template>

<script>
export default {
  name: 'CombatUserList',
  props: {
    users: {
      type: Array
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    userToggle (user) {
      this.$emit('user-toggle', user)
    }
  }
}
</script>

<style>

</style>

<template>
  <div
    class="combat-item rx-title"
    :class="theme"
  >
    <div class="combat-item__header d-flex align-items-center justify-content-between">
      <div>
        <div class="combat-item__title font-weight-bold">
          {{ combat.title }}
        </div>
        <div
          v-if="combat.status === combatStatuses.invitation"
          class="combat-item__goal-info"
        >
          <span class="mr-5">Цель: {{ combat.goal }}</span>
          <span>Период: {{ timeLeft }}</span>
        </div>
      </div>
      <div>
        <div
          v-if="combat.status !== combatStatuses.invitation && timeLeft.length"
          class="combat-item__timer text-center rounded">
          {{ timeLeft }}
        </div>
      </div>
    </div>
    <div class="combat-item__body d-flex justify-content-between">
      <div class="combat-item__enemy">
        <span class="combat-item__enemy-title">{{ enemyTitle }}</span>
        <div
          class="combat-item__enemy-info d-flex align-items-center"
          :class="enemiesView"
          >
          <template v-if="enemiesView === 'single'">
            <div>
              <img
                class="combat-item__enemy-image"
                :src="users[combat.enemy[0]].avatar"
                :alt="users[combat.enemy[0]].name"
              >
            </div>
            <div>
              <span class="combat-item__enemy-name font-weight-bold d-block">
                {{ users[combat.enemy[0]].name }}
              </span>
              <span class="combat-item__enemy-position rx-subtitle d-block">
                {{ users[combat.enemy[0]].post }}
              </span>
            </div>
          </template>
          <template v-else>
            <img
              v-for="index in combat.enemy" :key="index"
              class="combat-item__enemy-image"
              :src="users[index].avatar"
              :alt="users[index].name"
            >
          </template>
        </div>
      </div>

      <div class="combat-item__result">
        <template v-if="combat.status === combatStatuses.accepted">
          Отстаете на: <strong>30 000</strong>
        </template>
        <template v-else-if="combat.status === combatStatuses.declined">
          Поединок отклонен
        </template>
        <template v-else>
          <div
            class="btn btn-primary mr-3"
            @click="updateCombatStatus(combatStatuses.accepted)"
          >
            Принять вызов
          </div>
          <div
            class="btn btn-secondary"
            @click="updateCombatStatus(combatStatuses.declined)"
          >
            Отказаться
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import { COMBAT_STATUSES } from '@/common/consts'
import { mapGetters } from 'vuex'

export default {
  name: 'CombatItem',
  props: {
    combat: {
      type: Object
    },
    theme: {
      type: String,
      default: 'grey'
    }
  },
  data () {
    return {
      combatStatuses: COMBAT_STATUSES,
      timeLeft: ''
    }
  },
  computed: {
    ...mapGetters(['users']),
    enemyTitle () {
      return this.enemiesCount === 1 ? 'Противник' : 'Противники'
    },
    enemiesView () {
      return this.enemiesCount === 1 ? 'single' : 'list'
    },
    enemiesCount () {
      return this.combat.enemy.length
    }
  },
  mounted () {
    this.calcTimeLeft()
    setInterval(() => {
      this.calcTimeLeft()
    }, 30000)
  },
  methods: {
    async updateCombatStatus (status) {
      this.combat.status = status
      await this.$store.dispatch('updateCombat', {
        id: this.combat.id,
        data: { status: this.combat.status }
      })
    },
    calcTimeLeft () {
      const currentTime = new Date().getTime()
      const diff = (this.combat.deadline - currentTime) / 1000
      const days = Math.floor(diff / (3600 * 24))
      const hours = Math.floor(diff % (3600 * 24) / 3600)
      const minutes = Math.floor(diff % 3600 / 60)
      if (this.combat.status === this.combatStatuses.invitation) {
        this.timeLeft = `${days} д`
      } else {
        this.timeLeft = `${days}д : ${hours}ч : ${minutes}м`
      }
    }
  }
}
</script>

<style>
.combat-item {
  padding: 15px 30px 30px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.combat-item.grey {
  background: var(--grey-color);
}

.combat-item.green {
  background: #EBF9F0;
}

.combat-item.blue {
  background: #EDF4FE;
}

.combat-item:last-child {
  margin-bottom: 0;
}

.combat-item .combat-item__header {
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
}

.combat-item .combat-item__body {
  padding-top: 20px;
}

.combat-item .combat-item__timer {
  min-width: 50px;
  height: 35px;
  background: #fff;
  padding: 8px 15px 9px;
  font-weight: 600;
  white-space: nowrap;
}

.combat-item .combat-item__enemy-title {
  font-size: 12px;
  padding-bottom: 10px;
}

.combat-item .combat-item__enemy-info {
  padding-top: 10px;
}

.combat-item .combat-item__enemy-name {
  font-size: 14px;
}

.combat-item .combat-item__enemy-position {
  font-size: 12px;
}

.combat-item .combat-item__enemy-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.combat-item .combat-item__enemy-info.single .combat-item__enemy-image {
  margin-right: 15px;
}

.combat-item .combat-item__enemy-info.list .combat-item__enemy-image {
  margin-left: -15px;
}

.combat-item .combat-item__enemy-info.list .combat-item__enemy-image:first-child {
  margin-left: 0;
}

.combat-item .combat-item__result {
  padding-top: 5px;
  font-size: 14px;
}

.combat-item .combat-item__result strong {
  font-size: 13px;
}

.combat-item .combat-item__goal-info {
  padding-top: 15px;
  font-size: 13px;
  line-height: 1.4;
}
</style>

<template>
  <div class="start-page">
    <div class="stat-page__title rx-title">
      Combats
    </div>
    <div class="stat-page__description rx-title">
      Соревнуйтесь с коллегами за право быть лучшим. Превратите скучные задачи в невероятные сражения.
    </div>
    <div class="stat-page__buttons">
      <router-link
        to="/sale/arena/combats/create"
        class="btn btn-primary btn-lg mr-3"
      >
        <img
          class="mr-1"
          :src="plusIconSvg"
        />
        New game
      </router-link>

      <router-link
        to="/sale/arena/combats/list"
        class="btn btn-secondary btn-lg"
      >
        Перейти к списку поединков
      </router-link>
    </div>
  </div>
</template>

<script>
import plusIconSvg from '@/assets/svg/plus-rounded.svg'

export default {
  name: 'StartPage',
  data () {
    return {
      plusIconSvg
    }
  }
}
</script>

<style>
.start-page .stat-page__title {
  font-weight: 600;
  font-size: 36px;
  line-height: 1.36;
  margin-bottom: 20px;
}

.start-page .stat-page__description {
  font-size: 15px;
  line-height: 1.3;
  margin-bottom: 40px;
}
</style>

<template>
  <b-row>
    <b-col>
      Ваша команда
      <!-- <div>
        <input
          v-model="senderTeamName"
          placeholder="Название команды"
          type="text">
      </div> -->
      <div>
        <CombatUserList
          :users="senderTeamLocal"
          @user-toggle="senderTeamRemove"
          :checked="true" />
      </div>

      <template
        v-if="combatType !== 'duel'">
        <hr>
        <div>
          <!-- <input
            v-model="senderTeamSearch"
            placeholder="Поиск"
            type="text"> -->
          <CombatUserList
            :users="senderTeamList"
            @user-toggle="senderTeamSelect"
            />
        </div>
      </template>

    </b-col>
    <b-col>
      {{ senderTeamLocal.length }} x {{ enemyTeamLocal.length }}
    </b-col>
    <b-col>
      Команда противников
      <div>
        <CombatUserList
          :users="enemyTeamLocal"
          @user-toggle="enemyTeamRemove"
          :checked="true" />
      </div>

      <template
        v-if="combatType !== 'duel' || (combatType === 'duel' && enemyTeamLocal.length < 1)">
        <hr>
        <div>
          <!-- <input
            v-model="enemyTeamSearch"
            placeholder="Поиск"
            type="text"> -->
          <CombatUserList
            :users="enemyTeamList"
            @user-toggle="enemyTeamSelect"
            />
        </div>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import CombatUserList from '@/components/arena/CombatUserList'

export default {
  name: 'CombatUserChoose',
  components: {
    CombatUserList
  },
  props: {
    combatType: {
      type: String,
      default: 'duel'
    },
    senderTeam: {
      type: Array,
      default: () => []
    },
    enemyTeam: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      senderTeamLocal: this.senderTeam,
      enemyTeamLocal: this.enemyTeam,
      users: [
        {
          id: 1,
          avatar: 'https://bitrix2.cdnvideo.ru/b9626459/resize_cache/39288/ff58db95aecdfa09ae61b51b5fd8f63f/im/397/397132cfcb8a2d4a382c3b2d447b6a48/iOPVTIe3x84.jpg?h=ranx.bitrix24.ru',
          name: 'Вика Победоносная',
          wins_count: 12,
          points_count: '7,93M'
        },
        {
          id: 2,
          avatar: 'https://bitrix2.cdnvideo.ru/b9626459/resize_cache/39288/ff58db95aecdfa09ae61b51b5fd8f63f/im/397/397132cfcb8a2d4a382c3b2d447b6a48/iOPVTIe3x84.jpg?h=ranx.bitrix24.ru',
          name: 'Артем Броненосец',
          wins_count: 10,
          points_count: '8,32M'
        },
        {
          id: 318,
          avatar: 'https://bitrix2.cdnvideo.ru/b9626459/resize_cache/39288/ff58db95aecdfa09ae61b51b5fd8f63f/im/397/397132cfcb8a2d4a382c3b2d447b6a48/iOPVTIe3x84.jpg?h=ranx.bitrix24.ru',
          name: 'Павел Калачев',
          wins_count: 12,
          points_count: '7,93M'
        },
        {
          id: 319,
          avatar: 'https://bitrix2.cdnvideo.ru/b9626459/resize_cache/39288/ff58db95aecdfa09ae61b51b5fd8f63f/im/397/397132cfcb8a2d4a382c3b2d447b6a48/iOPVTIe3x84.jpg?h=ranx.bitrix24.ru',
          name: 'Василий Дубровский',
          wins_count: 12,
          points_count: '7,93M'
        },
        {
          id: 320,
          avatar: 'https://bitrix2.cdnvideo.ru/b9626459/resize_cache/39288/ff58db95aecdfa09ae61b51b5fd8f63f/im/397/397132cfcb8a2d4a382c3b2d447b6a48/iOPVTIe3x84.jpg?h=ranx.bitrix24.ru',
          name: 'Игорь Марков',
          wins_count: 12,
          points_count: '7,93M'
        }
      ],
      senderTeamList: [],
      enemyTeamList: []
    }
  },
  created () {
    this.senderTeamList = this.users.filter(user => this.usersId(this.senderTeamLocal).indexOf(user.id) === -1)

    this.enemyTeamList = this.users.filter(user => this.usersId(this.senderTeamLocal).indexOf(user.id) === -1)
  },
  methods: {
    usersId (users) {
      return users.map(user => {
        return user.id
      })
    },
    senderTeamSelect (user) {
      this.senderTeamList = this.senderTeamList.filter(el => el.id !== user.id)
      this.senderTeamLocal.push(user)

      this.enemyTeamList = this.enemyTeamList.filter(el => el.id !== user.id)
    },
    senderTeamRemove (user) {
      if (user.id !== 318) { // current user id
        this.senderTeamLocal = this.senderTeamLocal.filter(el => el.id !== user.id)
        this.senderTeamList.push(user)

        this.enemyTeamList.push(user)
      }
    },
    enemyTeamSelect (user) {
      this.enemyTeamList = this.enemyTeamList.filter(el => el.id !== user.id)
      this.senderTeamList = this.senderTeamList.filter(el => el.id !== user.id)

      this.enemyTeamLocal.push(user)
    },
    enemyTeamRemove (user) {
      this.enemyTeamLocal = this.enemyTeamLocal.filter(el => el.id !== user.id)
      this.enemyTeamList.push(user)

      this.senderTeamList.push(user)
    }
  }
}
</script>

<style>

</style>

<template>
  <div>
    <div v-if="isLoading"><Loader /></div>
    <b-row v-else>
      <b-col>
        <b-tabs>
          <b-tab active>
            <template #title>
              My combats
            </template>

            <div class="combats-block">
              <div
                v-if="activeCombats.length">
                <CombatItem
                  v-for="combat in activeCombats"
                  :key="combat.id"
                  :combat="combat"
                  theme="green"
                />
              </div>

              <router-link
                to="/sale/arena/combats/create"
                class="btn btn-primary d-block"
              >
                <img
                  class="mr-1"
                  :src="plusIconSvg"
                />
                New game
              </router-link>
            </div>
          </b-tab>

          <b-tab>
            <template #title>
              Invitations<span class="counter-rounded">{{ invitationCombats.length }}</span>
            </template>

            <div
              v-if="invitationCombats.length"
              class="combats-block"
            >
              <div>
                <CombatItem
                  v-for="combat in invitationCombats"
                  :key="combat.id"
                  :combat="combat"
                  theme="grey"
                />
              </div>
            </div>
          </b-tab>

          <b-tab>
            <template #title>
              History<span class="counter-rounded">87</span>
            </template>

            <p class="p-3">Tab contents 3</p>
          </b-tab>
        </b-tabs>

        <div
          v-if="allCombats.length"
          class="combats-block"
        >
          <div class="rx-title font-weight-bold subtitle">
            All combats<span class="counter-rounded">{{ allCombats.length }}</span>
          </div>

          <div>
            <CombatItem
              v-for="combat in allCombats"
              :key="combat.id"
              :combat="combat"
              theme="blue"
            />
          </div>
        </div>

      </b-col>
      <b-col>
        Rating
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import plusIconSvg from '@/assets/svg/plus-rounded.svg'
import CombatItem from '@/components/arena/CombatItem'

export default {
  components: {
    CombatItem
  },
  data () {
    return {
      isLoading: true,
      plusIconSvg
    }
  },
  computed: {
    ...mapGetters(['allCombats', 'activeCombats', 'invitationCombats'])
  },
  async created () {
    await this.$store.dispatch('fetchCombats')
    this.isLoading = false
  }
}
</script>

<style>
.subtitle {
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 20px;
}

.tab-content > .tab-pane {
  margin-top: 30px;
}

.combats-block {
  padding-bottom: 50px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 3px;
  margin-bottom: 35px;
}

.combats-block > .btn {
  margin-top: 30px;
}

.counter-rounded {
  margin-left: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  background: var(--bg-color);
  text-align: center;
  border-radius: 100px;
  padding: 1.5px 8px;
}
</style>

<template>
  <div class="arena-content">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Arena',
  components: {

  }
}
</script>

<style>
.arena-content {
  width: 100%;
  padding: 35px 50px;
  background: #fff;
}
</style>

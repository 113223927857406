<template>
  <div class="create-combat">
    <div class="create-combat__header d-flex justify-content-between align-items-center border-bottom">
      <div class="create-combat__back">
        <router-link
          to="/sale/arena/combats/"
        >
          Вернуться к списку
        </router-link>
      </div>
      <div class="create-combat__step">
        Этап {{ step }} из {{ maxStep }}
      </div>
    </div>
    <div
      v-if="step === 1"
      class="create-combat__type d-flex mb-4"
      >
      <div
        v-for="combat in combatTypes"
        :key="combat.name"
        @click="combatTypeChange(combat.value)"
        class="mr-3"
        :class="{ active: combat.value === combatTypeActive }"
        >
        {{ combat.text }}
      </div>
    </div>
    <div class="create-combat__body">
      <CombatUserChoose
        v-if="step === 1"
        :combatType="combatTypeActive"
        :senderTeam="senderTeam"
        :enemyTeam="enemyTeam"
        />

      <CombatOptionsChoose
        v-if="step === 2"
        :combatOption="combatOption"
        :combatOptionMeasure="combatOptionMeasure"
        />

      <template v-if="step !== maxStep">
        <div @click="step = step + 1">Cледующий шаг</div>
      </template>
      <template v-else>
        <div @click="step = step - 1">Предыдущий шаг</div>
        <div @click="combatCreate">Вызвать на поединок</div>
      </template>
    </div>
  </div>
</template>

<script>
import CombatUserChoose from '@/components/arena/CombatUserChoose'
import CombatOptionsChoose from '@/components/arena/CombatOptionsChoose'
import { mapGetters } from 'vuex'

export default {
  name: 'CombatCreate',
  components: {
    CombatUserChoose,
    CombatOptionsChoose
  },
  data () {
    return {
      combatTypes: [
        {
          value: 'duel',
          text: 'Одиночный бой'
        },
        {
          value: 'command',
          text: 'Командный бой'
        }
      ],
      combatTypeActive: 'duel',
      step: 2,
      maxStep: 2,

      senderTeam: [],
      senderTeamName: '',
      enemyTeam: [],
      combatOption: '',
      combatOptionMeasure: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['current'])
  },
  created () {
    this.senderTeam.push(
      {
        id: 318,
        avatar: 'https://bitrix2.cdnvideo.ru/b9626459/resize_cache/39288/ff58db95aecdfa09ae61b51b5fd8f63f/im/397/397132cfcb8a2d4a382c3b2d447b6a48/iOPVTIe3x84.jpg?h=ranx.bitrix24.ru',
        name: 'Павел Калачев',
        wins_count: 12,
        points_count: '7,93M'
      }
    )
  },
  methods: {
    combatTypeChange (combatType) {
      this.combatTypeActive = combatType

      if (combatType === 'duel') {
        if (this.senderTeam.length > 1) {
          this.senderTeam = this.senderTeam[0]
        }
        if (this.enemyTeam.length > 1) {
          this.enemyTeam = this.enemyTeam[0]
        }
      }
    },
    combatCreate () {
      // console.log('send data to server')
    }
  }
}
</script>

<style>
.create-combat__type .active {
  font-weight: 600;
}
</style>
